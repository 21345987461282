<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <br>
      <br>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12">
            <list-table-data
              :table-columns="tableColumns"
              :table-data="items"
              :redirect-edit="redirectEdit"
              :redirect-show="redirectShow"
              :origen="catalogoName"
              :show-icon-new="true"
              :companies="usuarios"
              :tipo-catalogo="catalogoType"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import ListTableData from '@/components/ListTableData.vue'
import { getlistado } from '@/utils/catalogos'
import { getUsersByCompany } from '@/utils/usuarios'

export default {
  components: {
    ListTableData,
    BCard,
    BRow,
    BCol,
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false)

    return {
      // Sidebar
      isAddNewUserSidebarActive,
    }
  },
  data() {
    return {
      usuario: JSON.parse(localStorage.getItem('userData')),
      catalogoName: 'Ruta',
      url: '/inspecciones/rutas',
      redirectEdit: 'inspecciones-rutas-show',
      redirectShow: 'inspecciones-rutas-show',
      items: [],
      tableColumns: [
        {
          label: 'Nombre',
          field: 'nombre',
        },
        {
          label: 'Tipo Ruta',
          field: 'tipoRuta',
        },
        {
          label: 'Estado',
          field: 'active',
        },
        {
          label: 'Acciones',
          field: 'acciones',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      usuarios: [],
      catalogoType: 8,
    }
  },
  async beforeMount() {
    try {
      this.items = await getlistado(this.catalogoType, this.catalogoName, this.usuario)
      if (this.items === null) this.items = []
      this.items = this.items.filter(i => i.active === true)

      const user = JSON.parse(localStorage.getItem('userData'))
      this.usuarios = await getUsersByCompany(user.idCompany) // await getUsersByCompanyRole(user.idCompany, 'inspecciones')
      this.usuarios.map(usuario => {
        // eslint-disable-next-line no-param-reassign
        usuario.nombre = `${usuario.firstName} ${usuario.firstSurname}`
        return usuario
      })
    } catch (err) {
      console.error(`Error en cargar ${this.catalogoName}`, err)
      this.items = []
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
